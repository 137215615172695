ul {
  list-style: none;
}
.card-container {
  height: 25%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  margin: 20px 20px;
  padding: 20px 20px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.card-container:hover {
  transition: 0.2s;
  cursor: pointer;
  transform: scale(1.05);
  filter: brightness(0.75);
}

.card-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 100%;
}

.card-name {
  margin: 0px;
  color: white;
  font-size: 40px;
  margin-bottom: 10px;
}

.card-img {
  border-color: black;
  border-width: 2.5px;
  border-style: solid;
  width: 50%;
  height: 50%;
  border-radius: 5px;
}

.card-description,
.card-price,
.card-count {
  margin: 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: white;
  padding-bottom: 4px;
}

#card-form {
  width: 50%;
}

.home {
  justify-content: center;
}
