body {
	margin: 0px;
} 

* {
	font-family: Arial;
}

h1 {
	text-align: center;
	font-size: 2.5rem;
	font-weight: bold;
	color: #333;
	margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {

	#about {
		display:flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		padding: 0px 15%;
		display: none;
	}

	.nav-element {
		padding: 1vw;
	}

	.navbar {
		padding: 2vw;
		height: 3vh;
	}

	.card-container {
		height: 25%;
		width: 55%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #333;
		margin: 20px 20px;
		padding: 20px 20px;
		border-radius: 10px;
		transition: all .2s ease-in-out;
	}
}