.home {
    height: 100%;
}

#root {
    height: 100%;
}

html {
    height: 100%;
}