html {
  box-sizing: border-box;
}

body {
  font-weight: 400;
  height: 100%;
}

.details-modal {
  position: fixed;
  background: #ffffff;
  border-radius: 0.5em;
  border-style: solid;
  border-color: black;
  box-shadow: 0 10px 20px rgba(black, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  display: flex;
  flex-direction: column;
}

.details-modal-title {
  margin: 0px;
  color: black;
  text-align: center;
  font-size: 40px;
}

.modal-image {
  border-color: black;
  border-width: 2.5px;
  border-style: solid;
  width: 50%;
  height: 50%;
  border-radius: 5px;
  margin: 5%;
}

.modal-close {
  position: absolute;
  left: 90%;
  padding-top: 5px;
}

.modal-delete {
  margin: 10px;
}

.modal-close:hover {
  transform: scale(1.05);
  font-weight: 500;
  cursor: pointer;
}
.details-modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-button {
  margin: 5px;
}
