.about-text {
    font-size: 1.2rem;
    color: #394f56;
    line-height: 1.5;
}

.about {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 25%;
}
